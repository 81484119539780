import { LocationAlarm } from '@hakimo-ui/hakimo/types';
import { HourglassIcon } from '@hakimo-ui/hakimo/ui-elements';
import { Label } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import AlarmStatusBadge from '../../shared/components/badge/AlarmStatusBadge';
import { tapColors } from '../../shared/constants';
import { useUpdateRenderedTime } from './useUpdateRenderedTime';

interface Props {
  alarm: LocationAlarm;
  selected: boolean;
  onClick: () => void;
}

export function ListItem(props: Props) {
  const { alarm, selected, onClick } = props;
  useUpdateRenderedTime(alarm.id);

  const tapBgColor = alarm.tap
    ? tapColors[Math.min(Math.floor(alarm.tap / 10), 9)]
    : null;

  return (
    <button
      onClick={onClick}
      className={clsx(
        'dark:bg-ondark-bg-2 bg-onlight-bg-2 hover:bg-onlight-bg-3 dark:hover:bg-ondark-bg-3 relative w-full cursor-pointer items-center outline-none',
        selected &&
          'dark:outline-ondark-primary outline-onlight-primary outline outline-offset-2',
        'animate-highlight dark:animate-dark-highlight'
      )}
    >
      <div className="flex items-stretch">
        <div
          className="font-oswald flex w-16 flex-shrink-0 items-center justify-center text-center text-3xl font-light text-white"
          style={{ backgroundColor: tapBgColor || 'inherit' }}
        >
          {tapBgColor ? (
            <span>{alarm.tap}</span>
          ) : (
            <span className="inline-block h-5 w-5 flex-shrink-0">
              <HourglassIcon />
            </span>
          )}
        </div>
        <div className="flex flex-1 flex-col gap-2 truncate px-4 py-3">
          <span
            className="text-onlight-text-1 dark:text-ondark-text-1 flex items-center justify-between truncate text-sm font-medium"
            title={alarm.location?.name}
          >
            <span className="overflow-hidden text-ellipsis">
              {alarm.location?.name}
            </span>
            <Label small>{alarm.rawAlarms.length}</Label>
          </span>
          <span className="flex items-center justify-between">
            <span className="dark:text-ondark-text-2 text-onlight-text-2 flex items-center gap-2 text-left text-sm">
              <span className="font-medium">
                {new Date(alarm.alarmTime).toLocaleTimeString()}
              </span>
              <span className="opacity-70">
                {new Date(alarm.alarmTime).toLocaleDateString()}
              </span>
            </span>
            <AlarmStatusBadge status={alarm.status} small />
          </span>
        </div>
      </div>
    </button>
  );
}

export default ListItem;
