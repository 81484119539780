import { User, UserDTO, UserListFilters } from '@hakimo-ui/hakimo/types';
import { Column, TableData } from '@hakimo-ui/hakimo/ui-table';
import { Button, Label } from '@hakimo-ui/shared/ui-base';
import { PencilIcon } from '@heroicons/react/24/outline';

export const columns: Column[] = [
  {
    id: 'name',
    name: 'Name',
  },
  {
    id: 'email',
    name: 'E-Mail',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'role',
    name: 'Role',
    showAtBreakpoint: 'md',
  },
  {
    id: 'mspLocations',
    name: 'MSP Locations',
    showAtBreakpoint: 'md',
  },
  {
    id: 'action',
    name: '',
    showAtBreakpoint: 'sm',
  },
];

export function getTableData(
  items: User[],
  canUpdate: boolean,
  onClickEdit: (userId: string) => void
): TableData {
  return {
    columns: columns,
    rows: items.map((item) => ({
      id: item.id,
      cells:
        item.enabled || item.enabled === undefined
          ? [
              {
                value: item.name,
              },
              { value: item.email },
              { value: item.roles[0] },
              {
                value:
                  item.mspLocations && item.mspLocations.length > 0 ? (
                    <div className="flex flex-wrap gap-2">
                      {item.mspLocations.map((loc) => (
                        <Label key={loc.id} small text={loc.name} />
                      ))}
                    </div>
                  ) : (
                    '-'
                  ),
              },
              {
                value: canUpdate ? (
                  <Button variant="icon" onClick={() => onClickEdit(item.id)}>
                    <PencilIcon className="w-4" />
                  </Button>
                ) : undefined,
              },
            ]
          : [
              {
                value: <span className="italic"> {item.name} (Disabled) </span>,
              },
              {
                value: <span className="italic"> {item.email} </span>,
              },
              {
                value: <span className="italic"> {item.roles[0]} </span>,
              },
              {
                value:
                  item.mspLocations && item.mspLocations.length > 0 ? (
                    <div className="flex flex-wrap gap-2">
                      {item.mspLocations.map((loc) => (
                        <Label key={loc.id} small text={loc.name} />
                      ))}
                    </div>
                  ) : (
                    '-'
                  ),
              },
              {
                value: undefined,
              },
            ],
    })),
  };
}

export function isNotDefaultFilter(filters: UserListFilters): boolean {
  return (
    filters.emails.length > 0 ||
    filters.names.length > 0 ||
    filters.roles.length > 0 ||
    !filters.enabled.showEnabled ||
    filters.enabled.showNotEnabled
  );
}

export const mapUserFilter = (filters: UserListFilters) => {
  const { emails, names, roles, enabled } = filters;
  const filtersApplied: Record<string, string[] | object> = {};
  emails.length > 0 && (filtersApplied['emails'] = emails);
  names.length > 0 && (filtersApplied['names'] = names);
  roles.length > 0 && (filtersApplied['roles'] = roles);
  filtersApplied['enabled'] = enabled;

  return filtersApplied;
};

export function getFormattedExportData(data: UserDTO): Record<string, any>[] {
  if (!data?.items || !Array.isArray(data.items)) {
    return [];
  }

  return data.items.map((item) => {
    const formattedItem = {
      Name: item.name || '-',
      Email: item.email || '-',
      Role: Array.isArray(item.roles)
        ? item.roles.join(', ')
        : item.roles || '-',
      'MSP Locations': Array.isArray(item.mspLocations)
        ? item.mspLocations.map((location) => location.name).join(', ')
        : '-',
    };

    return formattedItem;
  });
}
