/* eslint-disable max-lines */
import { Camera } from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import {
  CamAction,
  CamGroupDetails,
  EscalationState,
} from '../../../types/common';
import { EventHistory } from '../../../types/event';
import CamFeed from '../cam-feed/CamFeed';
import InvestigationView from './InvestigationView';

interface Props {
  groupId: string;
  camGroupDetails: CamGroupDetails;
  eventCamsSet?: Set<string>;
  onGroupAction: (
    cameraId: string,
    groupId: string,
    actionType: CamAction
  ) => void;
  camsEventHistoryMap: Record<string, EventHistory[]>;
  escalationState: EscalationState;
  onResolveEscalation: (comment?: string) => void;
}

export function CamGroup(props: Props) {
  const {
    camGroupDetails,
    eventCamsSet,
    groupId,
    onGroupAction,
    camsEventHistoryMap,
    escalationState,
    onResolveEscalation,
  } = props;

  const [shownCamIndex, setShownCamIndex] = useState(0);
  const [showInvestigationModal, setShowInvestigationModal] = useState(false);

  const eventCams = useMemo(() => {
    return eventCamsSet ? Array.from(eventCamsSet) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventCamsSet, eventCamsSet?.size]);

  const selectedCam: Camera | undefined = useMemo(() => {
    const camId = eventCams[shownCamIndex];
    const allCameras = camGroupDetails.cameras;
    return allCameras.find((c) => c.id === camId);
  }, [eventCams, camGroupDetails.cameras, shownCamIndex]);

  const handleCamAction = (actionType: CamAction) => () => {
    onGroupAction(selectedCam?.id ?? 'unknown-cam-id', groupId, actionType);
  };

  const onClickLeft = () =>
    shownCamIndex > 0 && setShownCamIndex(shownCamIndex - 1);

  const onClickRight = () =>
    shownCamIndex < eventCams.length - 1 && setShownCamIndex(shownCamIndex + 1);

  const activeEventCameras: Camera[] = useMemo(
    () =>
      eventCams
        .map((camId) => {
          const allCameras = camGroupDetails.cameras;
          return allCameras.find((c) => c.id === camId);
        })
        .filter((cam) => cam !== undefined) as Camera[],
    [eventCams, camGroupDetails.cameras]
  );

  const handleModalClose = () => setShowInvestigationModal(false);
  const openInvestigationModal = () => {
    onGroupAction(selectedCam?.id ?? '', groupId, CamAction.INVESTIGATE);
    setShowInvestigationModal(true);
  };
  const handleResolveEscalation = (comment?: string) => {
    handleModalClose();
    onResolveEscalation(comment);
  };

  const isHighPriorityEvent = useMemo(() => {
    return activeEventCameras.some((cam) => {
      const camEvents = camsEventHistoryMap[cam.id] ?? [];
      return camEvents.some((event) => event.severity === 'high');
    });
  }, [activeEventCameras, camsEventHistoryMap]);

  return (
    <div
      className={clsx(
        'relative max-h-full max-w-full flex-grow overflow-hidden rounded-md px-[1px]',
        escalationState.groupId === groupId && 'rounded-md bg-red-500',
        isHighPriorityEvent &&
          'rounded-md border-[3px] border-red-600 dark:border-orange-600'
      )}
    >
      <span
        title={camGroupDetails.groupName}
        className="text-ondark-text-1 from-dark-surface absolute top-[2px] left-[2px] z-10 w-2/3 truncate rounded-md bg-gradient-to-b p-[2px] text-xs"
      >
        {camGroupDetails.groupName}
      </span>
      <span className="text-ondark-text-1 from-dark-surface absolute top-[2px] right-[2px] z-10 rounded-md bg-gradient-to-b p-[2px] text-xs">{`${
        shownCamIndex + 1
      }/${eventCams.length}`}</span>
      {shownCamIndex > 0 && (
        <Button
          disabled={shownCamIndex < 1}
          variant="icon"
          onClick={onClickLeft}
          classNames="absolute left-1 top-[40%] z-10"
        >
          <ChevronLeftIcon className="h-3 w-3" />
        </Button>
      )}
      {shownCamIndex < eventCams.length - 1 && (
        <Button
          disabled={shownCamIndex >= eventCams.length - 1}
          variant="icon"
          onClick={onClickRight}
          classNames="absolute right-1 top-[40%] z-10"
        >
          <ChevronRightIcon className="h-3 w-3" />
        </Button>
      )}
      {
        selectedCam && (
          <div
            key={selectedCam.id}
            className="flex h-full w-full items-center justify-center rounded-md"
          >
            <CamFeed
              key={selectedCam.id}
              name={selectedCam.name}
              cameraTimeZone={selectedCam.location.timezone ?? 'UTC'}
              tenantName={selectedCam.tenantId || ''}
              livestreamUrl={selectedCam.livestreamUrl || ''}
              playbackUrl={selectedCam.visionPlaybackUrl}
              onClickSafe={
                groupId !== escalationState.groupId
                  ? handleCamAction(CamAction.SAFE)
                  : undefined
              }
              onClickSnooze={
                groupId !== escalationState.groupId
                  ? handleCamAction(CamAction.SNOOZE)
                  : undefined
              }
              onClickInvestigate={openInvestigationModal}
              eventHistory={camsEventHistoryMap[selectedCam.id] || []}
              onDoubleClick={openInvestigationModal}
            />
          </div>
        )
        // )
      }
      {showInvestigationModal && (
        <InvestigationView
          groupId={groupId}
          cameras={activeEventCameras}
          onClose={handleModalClose}
          handleCamAction={handleCamAction}
          camsEventHistoryMap={camsEventHistoryMap}
          escalationState={escalationState}
          onCreateEscalation={handleCamAction(CamAction.ESCALATE)}
          onResolveEscalation={handleResolveEscalation}
        />
      )}
    </div>
  );
}

export default CamGroup;
