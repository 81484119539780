/* eslint-disable max-lines */
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { EventHistory } from '../../../types/event';
import { formatTime, HOVER_MARGIN_TOP, TIMELINE_HEIGHT } from '../utils';
import { getHoverTranslate } from './utils';

interface Props {
  eventHistory: EventHistory[];
  onUpdatePlaybackSrc?: (params: string) => void;
  currentFrameId?: string;
  theme?: 'system' | 'dark';
  timezone: string;
}

export function CameraTimeline(props: Props) {
  const {
    currentFrameId,
    eventHistory,
    onUpdatePlaybackSrc,
    theme = 'dark',
    timezone,
  } = props;

  const timelineRef = useRef<HTMLDivElement>(null);
  const [hoverImagePosition, setHoverImagePosition] = useState<number | null>(
    null
  );
  const [hoverEvent, setHoverEvent] = useState<EventHistory>();
  const [hoverTimePosition, setHoverTimePosition] = useState<number | null>(
    null
  );

  const isHoverEnabled = !!onUpdatePlaybackSrc;

  const tenMinutesAgo = new Date(new Date().getTime() - 10 * 60 * 1000);

  const getEventPosition = (event: EventHistory) => {
    const timeDiff = event.createdTime - tenMinutesAgo.getTime();
    return (timeDiff / (10 * 60 * 1000)) * 100;
  };

  const relevantEvents =
    eventHistory?.filter(
      (event) => event.createdTime > tenMinutesAgo.getTime()
    ) ?? [];

  const onHoverEvent =
    (event: EventHistory) =>
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (timelineRef.current) {
        const rect = timelineRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const percentage = (x / rect.width) * 100;
        setHoverImagePosition(percentage);
        setHoverEvent(event);
      }
    };

  const onHoverTimeline = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const percentage = (x / rect.width) * 100;
      setHoverTimePosition(percentage);
    }
  };

  const onEventMouseLeave: React.MouseEventHandler<HTMLDivElement> = () =>
    setHoverImagePosition(null);

  const onTimelineMouseLeave: React.MouseEventHandler<HTMLDivElement> = () =>
    setHoverTimePosition(null);

  const getHoverTime = (position: number) => {
    if (position === null) return null;
    const hoverTime = new Date(
      tenMinutesAgo.getTime() + (position / 100) * 10 * 60 * 1000
    );
    return formatTime(hoverTime, timezone);
  };

  const onClickTimeline: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const percentage = (x / rect.width) * 100;
      const hoverTime = new Date(
        tenMinutesAgo.getTime() + (percentage / 100) * 10 * 60 * 1000
      );
      const hoverTimeISOFormat = hoverTime.toISOString();
      const duration = Math.floor((Date.now() - hoverTime.getTime()) / 1000);

      const params = new URLSearchParams();
      params.append('start', hoverTimeISOFormat);
      params.append('duration', String(duration));

      onUpdatePlaybackSrc?.(params.toString());
    }
  };

  return (
    <>
      {hoverImagePosition && hoverEvent && (
        <div
          className="absolute z-20 w-1/2"
          style={{
            left: `${hoverImagePosition}%`,
            bottom: `${TIMELINE_HEIGHT + HOVER_MARGIN_TOP}px`,
            transform: `translateX(-${getHoverTranslate(
              hoverImagePosition,
              timelineRef
            )})`,
          }}
        >
          <span className="bg-ondark-bg-2 absolute top-1 left-1 rounded-md px-2 text-xs text-white">
            {formatTime(new Date(hoverEvent.createdTime), timezone)}
          </span>
          <img
            src={hoverEvent.frameUrl}
            alt="event frame"
            className="border-ondark-text-1 rounded-md border"
          />
        </div>
      )}
      {hoverTimePosition && (
        <div
          className="bg-ondark-bg-3/70 absolute cursor-pointer rounded px-2 text-[9px] text-white"
          style={{
            left: `${hoverTimePosition}%`,
            transform: `translateX(-${getHoverTranslate(
              hoverTimePosition,
              timelineRef
            )})`,
            bottom: `${TIMELINE_HEIGHT + HOVER_MARGIN_TOP}px`,
          }}
        >
          {getHoverTime(hoverTimePosition)}
        </div>
      )}

      <div
        ref={timelineRef}
        className={clsx(
          'relative cursor-pointer rounded',
          theme === 'system'
            ? 'bg-onlight-bg-3 dark:bg-ondark-bg-3/80'
            : 'bg-ondark-bg-1/80'
        )}
        style={{ height: `${TIMELINE_HEIGHT}px` }}
        onMouseMoveCapture={isHoverEnabled ? onHoverTimeline : undefined}
        onMouseOverCapture={isHoverEnabled ? onHoverTimeline : undefined}
        onMouseLeave={onTimelineMouseLeave}
        onClick={onClickTimeline}
      >
        {relevantEvents.map((event, index) => (
          <div
            key={index}
            onMouseOverCapture={onHoverEvent(event)}
            onMouseLeave={onEventMouseLeave}
            className={clsx(
              'absolute top-0 h-full w-[0.12rem] transition-transform',
              event.severity === 'high' &&
                (event.eventId === currentFrameId
                  ? 'bg-green-500'
                  : 'bg-orange-300'),
              event.eventId === currentFrameId ? 'bg-green-500' : 'bg-red-500'
            )}
            style={{ left: `${getEventPosition(event)}%` }}
          ></div>
        ))}

        {hoverTimePosition && (
          <div
            className="bg-primary-500 pointer-events-none absolute z-10 w-[0.12rem]"
            style={{
              left: `${hoverTimePosition}%`,
              height: `${TIMELINE_HEIGHT}px`,
            }}
          ></div>
        )}
      </div>
    </>
  );
}

export default CameraTimeline;
