/* eslint-disable max-lines */
import { SEPARATOR } from '@hakimo-ui/hakimo/data-access';
import { Location, LocationFilters } from '@hakimo-ui/hakimo/types';
import {
  Cell,
  Column,
  Row,
  Column as TableColumn,
  TableData,
} from '@hakimo-ui/hakimo/ui-table';
import { Checkbox } from '@hakimo-ui/shared/ui-base';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid';

const actions = (
  <ChevronDoubleRightIcon className=" flex h-5 w-5 flex-shrink-0 items-center justify-between" />
);

export const columns: TableColumn[] = [
  { id: 'location_code', name: 'Location Code' },
  { id: 'is_manned', name: 'Staffed Location' },
  {
    id: 'name',
    name: 'Name',
  },
  {
    id: 'description',
    name: 'Description',
    showAtBreakpoint: 'sm',
  },
  {
    id: 'city',
    name: 'City',
    showAtBreakpoint: 'sm',
  },
  {
    id: 'state',
    name: 'State',
    showAtBreakpoint: 'md',
  },
  {
    id: 'country',
    name: 'Country',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'timeZone',
    name: 'Time Zone',
    showAtBreakpoint: 'xl',
  },
  {
    id: 'actions',
    name: '',
    showAtBreakpoint: 'sm',
  },
];

export function getTableData(
  items: Location[],
  onClickRowCb: (
    item: string,
    event: React.MouseEvent | React.KeyboardEvent
  ) => void,
  allColumns: Column[],
  shownColumns: string[]
): TableData {
  return {
    rows: items.map((location) => {
      const url = `/locations/${location.id}`;
      const cells = allColumns.reduce((acc, col) => {
        if (shownColumns.includes(col.id)) {
          switch (col.id) {
            case 'location_code': {
              acc.push({
                value: location.location_code ? location.location_code : '-',
              });
              break;
            }
            case 'is_manned': {
              acc.push({
                value: <Checkbox checked={!!location.is_manned} />,
              });
              break;
            }
            case 'actions': {
              acc.push({
                value: actions,
              });
              break;
            }
            default:
              acc.push({ value: location[col.id as keyof Location] });
              break;
          }
        }

        return acc;
      }, [] as Cell[]);
      const rowData: Row = {
        id: String(location.id),
        cells,
      };
      if (allColumns.some((col) => col.id === 'actions')) {
        rowData.onClick = (event: React.MouseEvent | React.KeyboardEvent) => {
          onClickRowCb(url, event);
        };
      }
      return rowData;
    }),
    columns: allColumns.filter((col) => shownColumns.includes(col.id)),
  };
}

export function getSearchParams(
  filters: LocationFilters,
  page?: number,
  pageSize?: number,
  searchValue?: string,
  selectedFilter?: FilterItem
): string {
  const params = new URLSearchParams();
  if (page) {
    params.append('page', String(page));
  }
  if (pageSize) {
    params.append('pageSize', String(pageSize));
  }

  if (filters.name.values.length > 0) {
    params.append(
      'name',
      filters.name.values.map((item) => item.name).join(SEPARATOR)
    );
  }
  if (searchValue && selectedFilter?.id) {
    params.append(selectedFilter.id, searchValue);
  }
  return params.toString();
}

export interface FilterItem {
  id: string;
  name: string;
}
export const searchFilters: FilterItem[] = [
  { id: 'name', name: 'Name' },
  {
    id: 'city',
    name: 'City',
  },
  {
    id: 'state',
    name: 'State',
  },
  {
    id: 'country',
    name: 'Country',
  },
  { id: 'location_code', name: 'Location Code' },
  { id: 'is_manned', name: 'Is Staffed' },
];

export function getFormattedExportData(items: any[]): Record<string, any>[] {
  if (!Array.isArray(items) || items.length === 0) {
    return [];
  }

  return items.map((item) => {
    const formattedItem = {
      'Location Code': item['location_code'] || '-',
      'Staffed Location':
        item['is_manned'] !== undefined ? item['is_manned'] : '-',
      Name: item['name'] || '-',
      Description: item['description'] || '-',
      City: item['city'] || '-',
      State: item['state'] || '-',
      Country: item['country'] || '-',
      TimeZone: item['timezone'] || '-',
    };

    return formattedItem;
  });
}
