/* eslint-disable max-lines */
import { useDoors } from '@hakimo-ui/hakimo/data-access';
import { Door, DoorFilters } from '@hakimo-ui/hakimo/types';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { TableData } from '@hakimo-ui/hakimo/ui-table';
import {
  eventTracker,
  trackFilters,
  useCanAddDcp,
  useCanRemoveMapping,
  useCanSeeDcpDetails,
  withAuthz,
  withErrorBoundary,
} from '@hakimo-ui/hakimo/util';
import { Alert, Button } from '@hakimo-ui/shared/ui-base';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FilterPanel from '../filter-panel/FilterPanel';
import { useAppDispatch, useAppState } from '../store/StateProvider';
import {
  createUpdateFiltersAction,
  createUpdatePageAction,
} from '../store/action-creators';
import { getSearchParams } from '../util';
import { DoorListModals } from './DoorListModals';
import DoorListTable from './DoorListTable';
import { DoorActionModal, columns, getTableData, mapDoorFilter } from './utils';

function DoorList() {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const {
    doorListFilters: filters,
    doorListPage: page,
    doorPageSize: pageSize,
  } = state;
  const [tableData, setTableData] = useState<TableData>({
    columns,
    rows: [],
  });
  const [allDoors, setAllDoors] = useState<Door[]>([]);
  const [total, setTotal] = useState(0);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [selectedDoorIds, setSelectedDoorIds] = useState<string[]>([]);
  const [doorToMap, setDoorToMap] = useState<Door>();
  const [activeModal, setActiveModal] = useState<DoorActionModal | null>();

  const { isFetching, isError, error, data } = useDoors(
    getSearchParams(filters, page, pageSize)
  );

  const fromIndex = (page - 1) * pageSize;
  const toIndex = Math.min(page * pageSize, total) - 1;
  const navigate = useNavigate();
  const canViewDcpDetails = useCanSeeDcpDetails();
  const onClickRow = useCallback(
    (door: Door) => {
      if (door.dcp && canViewDcpDetails) {
        navigate(`/doors/${door.dcp?.id}`);
      }
    },
    [navigate, canViewDcpDetails]
  );
  const canAddMapping = useCanAddDcp();
  const canRemoveMapping = useCanRemoveMapping();
  const onClickRemove = useCallback(
    (
      event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
      door: Door
    ) => {
      if (canRemoveMapping) {
        event.stopPropagation();
        if (door) {
          setDoorToMap(door);
          setActiveModal(DoorActionModal.REMOVE_MAPPING);
        }
      }
    },
    [canRemoveMapping]
  );
  const onClickAddMapping = useCallback(
    (
      event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
      door: Door
    ) => {
      if (canAddMapping) {
        event.stopPropagation();
        if (door) {
          setDoorToMap(door);
          setActiveModal(DoorActionModal.ADD_MAPPING);
        }
      }
    },
    [canAddMapping]
  );
  useEffect(() => {
    if (data) {
      const { items, total: t } = data;
      setTableData(
        getTableData(
          items,
          onClickRow,
          onClickRemove,
          onClickAddMapping,
          canRemoveMapping,
          canAddMapping
        )
      );
      setAllDoors(items);
      setTotal(t);
    }
  }, [
    data,
    onClickRow,
    onClickRemove,
    onClickAddMapping,
    canRemoveMapping,
    canAddMapping,
  ]);

  const onApplyFilters = (value: DoorFilters) => {
    dispatch(createUpdateFiltersAction(value));
    dispatch(createUpdatePageAction(1));
    trackFilters(mapDoorFilter(value));
  };

  return (
    <Page title="Doors">
      {isError && (
        <div className="mb-2">
          <Alert type="error">{error.message}</Alert>
        </div>
      )}
      {activeModal && (
        <DoorListModals
          activeModal={activeModal}
          onClose={() => setActiveModal(null)}
          selectedDoorIds={selectedDoorIds}
          allDoors={allDoors}
          doorToMap={doorToMap}
        />
      )}
      {canAddMapping ? (
        <div className="mb-2 flex justify-end">
          <Button
            variant="primary"
            onClick={() => setActiveModal(DoorActionModal.ADD_MAPPING)}
            disabled={isFetching}
            onSideEffect={eventTracker('open_add_door_camera_mapping_modal')}
          >
            Add Mapping
            <span className="sr-only">Add Mapping</span>
          </Button>
        </div>
      ) : null}
      <FilterPanel
        open={openFilterPanel}
        filters={filters}
        onApplyFilters={onApplyFilters}
        onClose={() => setOpenFilterPanel(false)}
      />
      <div className="dark:bg-dark-bg -mx-4 flex min-h-0 flex-1 flex-col justify-start overflow-y-hidden sm:-mx-6 md:mx-0 md:rounded-lg">
        <DoorListTable
          selected={selectedDoorIds}
          filters={filters}
          fromIndex={fromIndex}
          toIndex={toIndex}
          page={page}
          pageSize={pageSize}
          total={total}
          loading={isFetching}
          tableData={tableData}
          onChangeSelected={setSelectedDoorIds}
          onClickOpenFilter={() => setOpenFilterPanel(true)}
          onClickAddToLocation={() =>
            setActiveModal(DoorActionModal.ADD_TO_LOCATION)
          }
          onClickAddToDoorGroup={() =>
            setActiveModal(DoorActionModal.ADD_DOOR_GROUP)
          }
          onClickRemoveFromDoorGroup={() =>
            setActiveModal(DoorActionModal.REMOVE_DOOR_FROM_DOOR_GROUP)
          }
        />
      </div>
    </Page>
  );
}
export default withAuthz(withErrorBoundary(DoorList), ['door:view']);
