/* eslint-disable max-lines */
import { useCameras } from '@hakimo-ui/hakimo/data-access';
import { Camera } from '@hakimo-ui/hakimo/types';
import {
  toast,
  trackEvent,
  useAuthUtils,
  useLocalStorage,
  useUser,
} from '@hakimo-ui/hakimo/util';
import { Selectable } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useVisionEvents } from '../../hooks/useVisionEvents';
import {
  CamAction,
  CameraData,
  CamGroupDetails,
  CamLifecycleState,
  CountUpdate,
  EscalationState,
  GridConfig,
} from '../../types/common';
import {
  EventHistory,
  VisionEvent,
  VisionOutboundPayload,
  VisionOutboundType,
} from '../../types/event';
import { getQueryParams } from '../utils';
import DynamicLayout, { DynamicLayoutRef } from './DynamicLayout';
import ScanToolbar from './ScanToolbar';
import { SnoozedCams } from './SnoozedCams';
import {
  getCamToEventMapping,
  getPersonIds,
  getPersonIdsFromEventHistory,
  SNOOZE_TIME,
} from './utils';

interface Props {
  visionTenants: Selectable[];
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  onEscalate: (camera: Camera, groupId: string) => void;
  onCloseWs?: (event: CloseEvent) => void;
  onErrorWs?: (event: Event) => void;
  escalationState: EscalationState;
  onResolveEscalation: (
    comment: string,
    groupId: string,
    camToEventId: Array<[string, string]>
  ) => void;
}

export interface CamsMonitorRef {
  sendMessageInWebSocket: (data: VisionOutboundPayload) => void;
}

const CamsMonitor = forwardRef<CamsMonitorRef, Props>((props, ref) => {
  const {
    isFullScreen,
    toggleFullScreen,
    visionTenants,
    onEscalate,
    onCloseWs,
    onErrorWs,
    escalationState,
    onResolveEscalation,
  } = props;
  const [snoozedCamIds, setSnoozedCamIds] = useState<string[]>([]);
  const dynamicLayoutRef = useRef<DynamicLayoutRef>(null);
  const [camToEventHistoryMap, setCamToEventHistoryMap] = useState<
    Record<string, EventHistory[]>
  >({});
  const camToLifecycleStateRef = useRef<Record<string, CamLifecycleState>>({});
  const { getAccessToken } = useAuthUtils();
  const { data: allCamerasDto } = useCameras(
    getQueryParams(visionTenants, 'tenants')
  );
  const user = useUser();
  const defaultGridConfig = {
    cols: 4,
    rows: 4,
  };
  const [gridConfig, setGridConfig] = useLocalStorage<GridConfig>(
    'scan-grid-config',
    defaultGridConfig
  );
  const [camGroupDetailsMap, setCamGroupDetailsMap] = useState<
    Record<string, CamGroupDetails>
  >({}); // key is groupId
  const pendingAddCameraQueueRef = useRef<string[]>([]);

  const processPendingAddCameraQueue = (camItems: Camera[]) => {
    const pendingAddCamsQueue = pendingAddCameraQueueRef.current;
    if (pendingAddCamsQueue.length > 0) {
      const dynamicLayout = dynamicLayoutRef.current;
      if (!dynamicLayout) {
        return;
      }
      const newCamsToAdd = camItems.filter((cam) =>
        pendingAddCamsQueue.includes(cam.id)
      );
      dynamicLayout.addCameras(newCamsToAdd);
    }
  };

  const allCameras = useMemo(() => {
    const camItems = allCamerasDto?.items || [];
    // process pendingAddCameraQueue once api response comes
    if (camItems.length > 0) {
      processPendingAddCameraQueue(camItems);
    }
    return camItems;
  }, [allCamerasDto?.items]);

  useEffect(() => {
    if (allCameras && allCameras.length > 0) {
      const groupDetailsMap: Record<string, CamGroupDetails> = {};
      allCameras.forEach((c) => {
        const groupId = c.cameraGroupId || c.id;
        if (!groupDetailsMap[groupId]) {
          groupDetailsMap[groupId] = {
            groupName: c.cameraGroupName ?? c.name,
            tenantId: c.tenantId ?? 'unknown',
            cameras: [],
          };
        }
        groupDetailsMap[groupId].cameras.push(c);
      });
      setCamGroupDetailsMap(groupDetailsMap);
    }
  }, [allCameras]);

  const handleDetectionEvent = useCallback(
    (events: VisionEvent[]) => {
      const uniqueDetectionCamids = new Set();
      const uniqueDetectionEvents: VisionEvent[] = [];
      events.forEach((event) => {
        trackEvent('event_received', {
          cameraId: event.camera_id,
          tenantId: event.tenant_id,
          groupId: event.group_id,
        });
        if (!uniqueDetectionCamids.has(event.camera_id)) {
          uniqueDetectionCamids.add(event.camera_id);
          uniqueDetectionEvents.push(event);
        }
      });

      const dynamicLayout = dynamicLayoutRef.current;
      if (!dynamicLayout) {
        return;
      }
      const camToLifecycleState = camToLifecycleStateRef.current;
      const activeCamIds = dynamicLayout.getActiveCameras();
      const newCamToAdd: Camera[] = [];
      for (const detectionEvent of uniqueDetectionEvents) {
        const { camera_id: camId, tenant_id: tenantId } = detectionEvent;
        const isAlreadyActive = activeCamIds.some(
          (activeCamId) => activeCamId === camId
        );
        const isCamSnoozed = snoozedCamIds.some(
          (snoozedCamId) => snoozedCamId === camId
        );
        if (!isAlreadyActive && !isCamSnoozed) {
          const newCam = allCameras?.find((cam) => cam.id === camId);
          if (newCam) {
            newCamToAdd.push(newCam);
          } else {
            // sometime websocket message arrives early before camera api response.
            // adding it to queue and processed after api response
            const pendingAddCamsQueue = [...pendingAddCameraQueueRef.current];
            pendingAddCamsQueue.push(camId);
          }

          camToLifecycleState[camId] = {
            enqueuedAt: Date.now(),
            shownInGridAt: 0,
          };

          trackEvent('event_triggering_camera_pop_up', {
            cameraId: camId,
            tenantId,
          });
        }
      }

      newCamToAdd.length > 0 && dynamicLayout.addCameras(newCamToAdd);

      // save most recent detection event for all cameras
      // this will be used while sending safe event
      events.forEach((event) => {
        if (camToLifecycleState[event.camera_id]) {
          camToLifecycleState[event.camera_id].lastDetectionEvent = event;
        }
      });
      camToLifecycleStateRef.current = camToLifecycleState;
    },
    [allCameras, snoozedCamIds]
  );

  const handleHistoryEvent = (events: VisionEvent[]) => {
    setCamToEventHistoryMap((prev) => {
      const updatedMap = { ...prev };
      const dynamicLayout = dynamicLayoutRef.current;
      const activeCamIds = dynamicLayout?.getActiveCameras() ?? [];

      const filteredEvents =
        activeCamIds.length > 0
          ? events.filter((ev) => activeCamIds.includes(ev.camera_id))
          : events;

      filteredEvents.forEach((event) => {
        const camId = event.id;
        if (!updatedMap[camId]) {
          updatedMap[camId] = [];
        }
        const newEvents: EventHistory[] =
          event.metadata?.frames?.map(([url, timestamp]) => ({
            createdTime: timestamp,
            frameUrl: url,
            id: event.id,
            eventId: event.event_id,
            cameraId: event.camera_id,
            cameraName: event.camera_name,
            personIds: getPersonIds(event.metadata),
            severity: event.severity,
          })) ?? [];
        updatedMap[camId] = [...updatedMap[camId], ...newEvents];
      });
      return updatedMap;
    });
  };

  const handleRebalanceEvent = () => {
    toast('A new operator has joined Scan. Your workload will now be balanced');
  };

  const socketUrl = useMemo(() => {
    const allTenants = visionTenants.map((tenant) => tenant.id).join(',');

    const host = window.location.hostname;
    return `wss://event-flow-${host}/events?tenants=${allTenants}`;
  }, [visionTenants]);

  const { sendMessage: sendMessageInWS } = useVisionEvents(
    socketUrl,
    getAccessToken,
    handleDetectionEvent,
    handleHistoryEvent,
    handleRebalanceEvent,
    onCloseWs,
    onErrorWs
  );

  const fetchCamHistory = useCallback(
    (camsData: CameraData[]) => {
      const camToLifecycleState = camToLifecycleStateRef.current;
      camsData.forEach((camData) => {
        const { camId, groupId } = camData;
        const tenantId = camGroupDetailsMap[groupId].tenantId;
        if (camToLifecycleState[camId]) {
          camToLifecycleState[camId] = {
            ...camToLifecycleState[camId],
            shownInGridAt: Date.now(),
          };
        }
        sendMessageInWS({
          camera_id: camId,
          group_id: groupId,
          tenant_id: tenantId || '',
          event_type: VisionOutboundType.HISTORY,
        });
      });
    },
    [camGroupDetailsMap, sendMessageInWS]
  );

  const sendMessageInWebSocket = (data: VisionOutboundPayload) => {
    sendMessageInWS(data);
  };

  useImperativeHandle(ref, () => ({
    sendMessageInWebSocket,
  }));

  const onArrangeSmartly = () => {
    const dynamicLayout = dynamicLayoutRef.current;
    if (!dynamicLayout) {
      return;
    }
    dynamicLayout.arrangeSmartly();
  };

  const cleanLifecycleStateForGroup = (groupId: string) => {
    const groupCams = camGroupDetailsMap[groupId].cameras;
    const camToLifecycleState = camToLifecycleStateRef.current;
    groupCams.forEach((cam) => {
      const camId = cam.id;
      delete camToLifecycleState[camId];
    });
    camToLifecycleStateRef.current = camToLifecycleState;
  };

  const cleanHistoryEvents = (groupId: string) => {
    const groupCams = camGroupDetailsMap[groupId].cameras;
    //clear history events for group cams
    const updatedCamToHistoryMap = { ...camToEventHistoryMap };
    groupCams.forEach((cam) => {
      if (updatedCamToHistoryMap[cam.id]) {
        updatedCamToHistoryMap[cam.id] = [];
      }
    });
    setCamToEventHistoryMap(updatedCamToHistoryMap);
  };

  const cleanupForGroup = (groupId: string) => {
    cleanLifecycleStateForGroup(groupId);
    cleanHistoryEvents(groupId);
  };

  const onCameraAction = (
    cameraId: string,
    groupId: string,
    actionType: CamAction,
    eventCamIds: string[]
  ) => {
    const camToLifecycleState = camToLifecycleStateRef.current;
    const groupCams = camGroupDetailsMap[groupId].cameras;
    const actionCam = groupCams.find((cam) => cam.id === cameraId);
    const eventCamToEventId = getCamToEventMapping(
      eventCamIds,
      camToLifecycleState
    );
    switch (actionType) {
      case CamAction.SNOOZE: {
        const groupCamIds = groupCams.map((c) => c.id);
        setSnoozedCamIds((prev) => [...prev, ...groupCamIds]);
        // remove after SNOOZE_TIME
        setTimeout(() => {
          setSnoozedCamIds((prev) => {
            const updatedCams = prev.filter(
              (camId) => !groupCamIds.includes(camId)
            );
            return updatedCams;
          });
        }, SNOOZE_TIME * 1000);
        break;
      }
      case CamAction.ESCALATE:
      case CamAction.INVESTIGATE: {
        // Escalation will be handled in such a way that groupId will be sent along with escalation.
        // and all event cams inside the group will be attached to the escalation
        if (!actionCam) {
          return;
        }
        let eventType = VisionOutboundType.INVESTIGATE;
        if (actionType === CamAction.ESCALATE) {
          eventType = VisionOutboundType.ESCALATION_OPEN;
          onEscalate(actionCam, groupId);
        }
        sendMessageInWS({
          camera_id: actionCam?.id || '',
          group_id: groupId,
          tenant_id: actionCam?.tenantId || '',
          event_type: eventType,
          additional_data: {
            username: user.email,
            camera_display_timestamp:
              camToLifecycleState[actionCam.id].shownInGridAt,
            camera_enqueue_timestamp:
              camToLifecycleState[actionCam.id].enqueuedAt,
            event_timestamp: Date.now(),
            camera_id_to_event_id: eventCamToEventId,
          },
        });
        break;
      }
      case CamAction.SAFE: {
        if (!actionCam) {
          return;
        }

        const personIds = getPersonIdsFromEventHistory(
          camToEventHistoryMap[actionCam.id]
        );

        sendMessageInWS({
          camera_id: actionCam?.id || '',
          group_id: groupId,
          tenant_id: actionCam?.tenantId || '',
          event_type: VisionOutboundType.SAFE,
          person_ids: personIds ?? [], // person ids from the camera for which safe is clicked.
          additional_data: {
            username: user.email,
            camera_display_timestamp:
              camToLifecycleState[actionCam.id].shownInGridAt,
            camera_enqueue_timestamp:
              camToLifecycleState[actionCam.id].enqueuedAt,
            event_timestamp: Date.now(),
            camera_id_to_event_id: eventCamToEventId,
          },
        });

        cleanupForGroup(groupId);
        break;
      }
      default:
        break;
    }
  };

  const unSnoozeCam = (camId: string) => {
    const updatedSnoozedCamIdss = [...snoozedCamIds];
    const camIndex = updatedSnoozedCamIdss.findIndex((cam) => cam === camId);
    camIndex > -1 && updatedSnoozedCamIdss.splice(camIndex, 1);
    setSnoozedCamIds(updatedSnoozedCamIdss);
    trackEvent('camera_unsnoozed', {
      cameraId: camId,
    });
  };

  const snoozedCameras = allCameras.filter((item) =>
    snoozedCamIds.includes(item.id)
  );

  const onCameraCountUpdate = useCallback(
    (countUpdate: CountUpdate) => {
      sendMessageInWS({
        event_type: VisionOutboundType.CAMERA_COUNT,
        additional_data: {
          username: user.email,
          active_camera_count: countUpdate.camerasInGridCount, // camera count shown in grid
          queue_camera_count: countUpdate.camerasInQueueCount,
          active_group_count: countUpdate.groupsInGridCount, // group count shown in grid
          queue_group_count: countUpdate.groupInQueueCount,
          snoozed_camera_count: snoozedCamIds.length,
        },
      });
    },
    [sendMessageInWS, snoozedCamIds.length, user.email]
  );

  const handleResolveEscalation = (
    message: string,
    groupId: string,
    eventCamIds: string[]
  ) => {
    cleanupForGroup(groupId);
    const camToLifecycleState = camToLifecycleStateRef.current;
    const eventCamToEventId = getCamToEventMapping(
      eventCamIds,
      camToLifecycleState
    );
    onResolveEscalation(message, groupId, eventCamToEventId);
  };

  return (
    <div
      className={clsx(
        'flex flex-col',
        isFullScreen ? 'h-[calc(100vh-2rem)]' : 'h-[calc(100%-8px)]'
      )}
    >
      <div className="w-full flex-grow">
        <DynamicLayout
          ref={dynamicLayoutRef}
          isFullScreen={isFullScreen}
          onCellAction={onCameraAction}
          camsEventHistoryMap={camToEventHistoryMap}
          onCameraCountUpdate={onCameraCountUpdate}
          gridConfig={gridConfig ?? defaultGridConfig}
          camGroupDetailsMap={camGroupDetailsMap}
          escalationState={escalationState}
          onResolveEscalation={handleResolveEscalation}
          onCamShownInGrid={fetchCamHistory}
        />
      </div>
      <div className="mr-14 flex max-w-[calc(100vw-24px)] items-center gap-2">
        <SnoozedCams
          cameras={snoozedCameras || []}
          unSnoozeCam={unSnoozeCam}
          camsEventHistoryMap={camToEventHistoryMap}
        />
        <ScanToolbar
          toggleFullScreen={toggleFullScreen}
          isFullScreen={isFullScreen}
          onArrangeSmartly={onArrangeSmartly}
          gridConfig={gridConfig ?? defaultGridConfig}
          updateGridConfig={setGridConfig}
        />
      </div>
    </div>
  );
});

export default CamsMonitor;
