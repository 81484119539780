/* eslint-disable max-lines */
import { Select } from '@hakimo-ui/hakimo/ui-elements';
import { Column, ColumnsMenu } from '@hakimo-ui/hakimo/ui-table';
import {
  eventTracker,
  useDebounce,
  useExportCsv,
} from '@hakimo-ui/hakimo/util';
import { Button, InputField } from '@hakimo-ui/shared/ui-base';
import { ArrowDownTrayIcon, FunnelIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import {
  FilterItem,
  searchFilters,
  getFormattedExportData,
  getSearchParams,
} from './util';
import { LocationFilters } from '@hakimo-ui/hakimo/types';
import { useLocations } from '@hakimo-ui/hakimo/data-access';

interface Props {
  canAddLocation: boolean;
  hasCustomFiltersApplied: boolean;
  isLoading: boolean;
  onClickAddLocation: () => void;
  onClickFilter: () => void;
  onSearch: (value: string) => void;
  filterValue: FilterItem;
  onChangeFilter: (value: FilterItem) => void;
  allColumns: Column[];
  shownColumns: string[];
  onChangeShownColumns: (val: string[]) => void;
  filters: LocationFilters;
}

export function TableHeader(props: Props) {
  const {
    canAddLocation,
    hasCustomFiltersApplied,
    isLoading,
    onClickFilter,
    onClickAddLocation,
    onSearch,
    filterValue,
    onChangeFilter,
    allColumns,
    onChangeShownColumns,
    shownColumns,
    filters,
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 400);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    onSearch(debouncedSearchValue);
  }, [debouncedSearchValue, onSearch]);
  const displayValue = (item?: FilterItem) => {
    if (item) return item?.name;
    return '';
  };

  const onSearchValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const onChange = (value: FilterItem | null) => {
    value && onChangeFilter(value);
    setSearchValue('');
  };

  const allColumnsWithoutActions = useMemo(
    () => allColumns.filter((col) => col.id !== 'actions'),
    [allColumns]
  );
  const staffedFilterOptions = [
    { label: 'All Locations', value: '' },
    { label: 'Staffed Locations', value: true },
    { label: 'Unstaffed Locations', value: false },
  ];

  const exportSearchParams = getSearchParams(filters);
  const { refetch: fetchExportData } = useLocations(exportSearchParams, false);

  const { exportCsv } = useExportCsv();
  const handleExport = async () => {
    setIsExporting(true);
    try {
      const { data } = await fetchExportData();
      if (!data?.items) {
        throw new Error('No data to export');
      }

      const formattedData = getFormattedExportData(data.items);
      exportCsv({
        formattedData,
        fileName: 'hakimo-locations-export.csv',
        fileType: 'csv',
      });
    } catch (error) {
      throw new Error('Export Failed');
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <div
      className={clsx(
        'dark:bg-dark-bg flex items-center border-b bg-white p-4 dark:border-0',
        canAddLocation ? 'justify-between' : 'justify-end'
      )}
    >
      <div className="flex items-center justify-around">
        {canAddLocation && (
          <Button
            variant="primary"
            onClick={onClickAddLocation}
            disabled={isLoading}
            onSideEffect={eventTracker('open_add_location_modal')}
          >
            Add Location
            <span className="sr-only">Add Location</span>
          </Button>
        )}
      </div>

      <div className="flex flex-row items-center justify-end gap-4 ">
        <div className=" bg-onlight-bg-2 dark:bg-ondark-bg-2 flex flex-row items-center justify-end gap-2 rounded-md p-1">
          {filterValue.id === 'is_manned' ? (
            <Select
              value={staffedFilterOptions.find(
                (opt) => String(opt.value) === searchValue
              )}
              placeholder="Select a relevant option"
              getItems={() => staffedFilterOptions}
              displayValue={(item) => item?.label || ''}
              onChange={(val) => setSearchValue(String(val?.value) || '')}
            />
          ) : (
            <InputField
              label=""
              onChange={onSearchValChange}
              value={searchValue}
              type="search"
              placeholder={`Filter with ${filterValue.name}...`}
            />
          )}
          <div className="w-60">
            <Select
              value={filterValue}
              getItems={() => searchFilters}
              displayValue={displayValue}
              onChange={onChange}
            />
          </div>
        </div>
        <ColumnsMenu
          options={allColumnsWithoutActions}
          shownColumns={shownColumns}
          onChangeShownColumns={onChangeShownColumns}
        />
        <div className="">
          <Button
            variant="icon"
            onClick={handleExport}
            loading={isExporting}
            title="Download Data"
          >
            <ArrowDownTrayIcon className="w-5" />
          </Button>
        </div>
        <Button
          variant="icon"
          badge={hasCustomFiltersApplied}
          onClick={onClickFilter}
          disabled={isLoading}
          onSideEffect={eventTracker('open_location_filters_panel')}
        >
          <span className="sr-only">Open filters panel</span>
          <FunnelIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
}

export default TableHeader;
