import { useScanEscalations } from '@hakimo-ui/hakimo/data-access';
import { ListView } from '@hakimo-ui/hakimo/feature-shared';
import {
  EscalationListFilterValues,
  ScanEscalation,
} from '@hakimo-ui/hakimo/types';
import { SplitView, SplitViewWrapper } from '@hakimo-ui/hakimo/ui-elements';
import { FilterPanel } from '@hakimo-ui/hakimo/ui-filters';
import { trackFilters } from '@hakimo-ui/hakimo/util';
import { Alert, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import EscalationDetails from '../escalation-details/EscalationDetails';
import FiltersHeader from './FiltersHeader';
import ListItem from './ListItem';
import { getSearchParams, useWithTimeFilterValue } from './request-utils';
import {
  escalationFiltersAtom,
  pageAtom,
  pageSizeAtom,
  timePeriodAtom,
} from './state';
import { filterConfigs } from './util';

export function EscalationsList() {
  const [page, setPage] = useAtom(pageAtom);
  const pageSize = useAtomValue(pageSizeAtom);
  const [period, setPeriod] = useAtom(timePeriodAtom);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [escalationFilters, setEscalationFilters] = useAtom(
    escalationFiltersAtom
  );
  const escalationFiltersWithTime = useWithTimeFilterValue(
    escalationFilters,
    period
  );
  const queryparams = getSearchParams(
    page,
    pageSize,
    escalationFiltersWithTime
  );

  const { data, error, isError, isFetching, isLoading } =
    useScanEscalations(queryparams);

  useEffect(() => {
    if (!data) {
      return;
    }
    const { items } = data;
    if (items === undefined || items.length === 0) {
      return;
    }
    const shouldSetSelectedItem =
      !selectedId || !items.some((item) => item.id === selectedId);
    if (shouldSetSelectedItem) {
      setSelectedId(items[0].id);
    }
  }, [data, selectedId]);

  const changeSelected = (item: ScanEscalation) => {
    setSelectedId(item.id);
  };

  const handleChangePage = (pageNum: number) => {
    setPage(pageNum);
  };

  const onApplyFilters = (values: EscalationListFilterValues) => {
    setEscalationFilters(values);
    trackFilters(values);
    setPage(1);
    setOpenFilterPanel(false);
  };

  const selectedEscalation = data?.items.find((item) => item.id === selectedId);

  return (
    <>
      {isError && (
        <Alert type="error">
          Error getting scan esclations. {error.message}
        </Alert>
      )}
      <SplitViewWrapper>
        <SplitView>
          <div className="flex max-h-full w-[20rem] flex-col divide-y dark:divide-white/10">
            <FiltersHeader
              period={period}
              onChangePeriod={setPeriod}
              onClickOpenFilterPanel={() => setOpenFilterPanel(true)}
              filterValues={escalationFilters}
              isLoading={isFetching}
            />
            <div className="overflow-auto scroll-smooth py-4 pr-4 pb-4 pl-1">
              <ListView
                items={data?.items || []}
                total={data?.total || 1000}
                page={page}
                pageSize={pageSize}
                onChangePage={handleChangePage}
                renderListItem={(item) => (
                  <ListItem
                    escalation={item}
                    selected={item.id === selectedId}
                    onClick={() => changeSelected(item)}
                  />
                )}
              />
            </div>
          </div>
        </SplitView>
        <SplitView>
          <>
            {(isLoading || isFetching) && (
              <div className="flex h-full items-center justify-center">
                <HakimoSpinner />
              </div>
            )}
            {selectedEscalation ? (
              <EscalationDetails escalation={selectedEscalation} />
            ) : (
              <div className="flex h-full items-center justify-center">
                No Escalation selected
              </div>
            )}
          </>
        </SplitView>
      </SplitViewWrapper>
      <FilterPanel
        title="Escalations Filters"
        open={openFilterPanel}
        filterConfigs={filterConfigs}
        initialFilterValues={escalationFilters}
        trackingEventNameApply="apply_escalation_list_filters"
        trackingEventNameReset="reset_escalation_list_filters"
        onClose={() => setOpenFilterPanel(false)}
        onApply={onApplyFilters}
      />
    </>
  );
}
