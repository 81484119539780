import { SEPARATOR } from '@hakimo-ui/hakimo/data-access';
import { DoorFilters } from '@hakimo-ui/hakimo/types';

export function getSearchParams(
  filters: DoorFilters,
  page?: number,
  pageSize?: number
): string {
  const params = new URLSearchParams();
  if (page) {
    params.append('page', String(page));
  }
  if (pageSize) {
    params.append('pageSize', String(pageSize));
  }

  const labelStatuses = [];

  if (filters.labelStatus.showLabeled) {
    labelStatuses.push('completed');
  }

  if (filters.labelStatus.showUnlabeled) {
    labelStatuses.push('pending');
  }

  if (labelStatuses.length > 0) {
    params.append('labelStatus', labelStatuses.join(SEPARATOR));
  }

  if (filters.location.values.length > 0) {
    params.append(
      'locationIds',
      filters.location.values.map((item) => item.id).join(SEPARATOR)
    );
  }
  if (filters.door.values.length > 0) {
    params.append(
      'doors',
      filters.door.values.map((item) => item.name).join(SEPARATOR)
    );
  }

  return params.toString();
}
