import { TimePeriod } from '@hakimo-ui/hakimo/types';
import { useCanUseAllTimeFilter } from '@hakimo-ui/hakimo/util';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { ClockIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Fragment } from 'react';
import { timePeriodOptions } from '../constants';

interface Props {
  period: TimePeriod;
  onChange: (period: TimePeriod) => void;
}

export const TimePeriodSelector = (props: Props) => {
  const { period, onChange } = props;

  const canUseAllTimeFilter = useCanUseAllTimeFilter();
  const periodOptions = canUseAllTimeFilter
    ? timePeriodOptions
    : timePeriodOptions.filter((opt) => opt.name !== TimePeriod.ALL_TIME);
  const selectedOption = periodOptions.find((opt) => opt.name === period);

  return (
    <div className="flex items-center gap-2">
      <Listbox value={selectedOption} onChange={(opt) => onChange(opt.name)}>
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">
              Change time period
            </Listbox.Label>
            <div className="relative">
              <div className="inline-flex items-center gap-2">
                <div className="dark:text-ondark-text-2 text-onlight-text-2 inline-flex items-center">
                  <ClockIcon className="h-5 w-5" aria-hidden="true" />
                  <p className="ml-2.5 text-sm font-medium">
                    {selectedOption?.name}
                  </p>
                </div>
                <Listbox.Button className="dark:hover:bg-ondark-bg-2 hover:bg-onlight-bg-2 dark:focus:ring-ondark-primary focus:ring-onlight-primary dark:focus:ring-offset-ondark-bg-1 focus:ring-offset-onlight-bg-1 inline-flex items-center rounded-md p-1 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2">
                  <span className="sr-only">Change time period</span>
                  <ChevronDownIcon
                    className="dark:text-ondark-text-2 text-onlight-text-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Listbox.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="dark:bg-ondark-bg-2 bg-onlight-bg-2 absolute z-10 mt-1 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {periodOptions.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        clsx(
                          active
                            ? 'bg-onlight-primary dark:bg-ondark-primary text-white'
                            : 'text-onlight-text-1 dark:text-ondark-text-1',
                          'relative cursor-default select-none px-3 py-2'
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <div className="flex w-full flex-col">
                          <div className="flex justify-between">
                            <p
                              className={selected ? 'font-bold' : 'font-normal'}
                            >
                              {option.name}
                            </p>
                            {selected ? (
                              <span
                                className={
                                  active
                                    ? 'text-white'
                                    : 'text-onlight-primary dark:text-ondark-primary'
                                }
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default TimePeriodSelector;
