import { Acknowledgement } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useAcknowledgementsByLocationId(locationId: number) {
  const url = `/v2/orm/acknowledgements/${locationId}`;
  const request = new Request(url);

  return useAuthenticatedRequest<Acknowledgement[]>(request, {
    queryKey: ['acknowledgements', locationId],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useAcknowledgementsByLocationId;
