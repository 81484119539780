import { useAcknowledgementsByLocationId } from '@hakimo-ui/hakimo/data-access';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useMemo } from 'react';

interface Props {
  locationId: number;
}
export function Acknowledgements(props: Props) {
  const { locationId } = props;

  const { data: pendingAcks } = useAcknowledgementsByLocationId(locationId);

  const warningPendingAcks = useMemo(() => {
    if (pendingAcks) {
      return pendingAcks.some(
        (ack) =>
          Date.now() - new Date(ack.created_at_utc).getTime() > 2 * 60 * 1000 // > 2 minutes
      );
    } else {
      return false;
    }
  }, [pendingAcks]);

  if (!warningPendingAcks) {
    return;
  }

  return (
    <div>
      <Alert type="warning" className="">
        <div className="space-y-2">
          <span>
            Monitoring window updates are pending on the appliance. Please
            contact the admin to resolve.
          </span>
          <details className="space-y-2">
            <summary>Expand to see pending acknowledgement details</summary>
            {pendingAcks?.map((ack, i) => (
              <li key={i} className="ml-4">
                {ack.message_type} at {ack.created_at_utc} for {ack.device_id}{' '}
                triggered by {ack.created_by_user_name}
              </li>
            ))}
          </details>
        </div>
      </Alert>
    </div>
  );
}

export default Acknowledgements;
