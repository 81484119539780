/* eslint-disable max-lines */
import { useUpdateTenantDisarmWindow } from '@hakimo-ui/hakimo/data-access';
import { TimezoneSelect } from '@hakimo-ui/hakimo/feature-shared';
import {
  SiteDisarmWindow,
  UpdateSiteDisarmWindowPayload,
} from '@hakimo-ui/hakimo/types';
import { eventTracker, toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, InputField, Modal } from '@hakimo-ui/shared/ui-base';
import { ChangeEvent, useState } from 'react';
import {
  convertDateStringToTimestamp,
  convertTimestampToFormattedDate,
  validateDisarmWindow,
} from './util';

interface Props {
  tenantId: string;
  locationId: string;
  onClose: () => void;
  siteDisarmWindow?: SiteDisarmWindow;
  isRelevantWindow?: boolean;
}

const defaultSiteDisarmWindow: SiteDisarmWindow = {
  startTimestamp: 0,
  endTimestamp: 1,
  timezone: 'UTC',
};

export function UpdateMonitoringDisarmModal(props: Props) {
  const {
    tenantId,
    locationId,
    siteDisarmWindow = defaultSiteDisarmWindow,
    onClose,
    isRelevantWindow,
  } = props;
  const [siteDisarmWindowPayload, setSiteDisarmWindowPayload] =
    useState<UpdateSiteDisarmWindowPayload>(() => ({
      startTime: isRelevantWindow ? siteDisarmWindow.startTimestamp : 0,
      endTime: isRelevantWindow ? siteDisarmWindow.endTimestamp : 0,
      timezone: siteDisarmWindow.timezone,
      tenantId,
      locationId,
    }));
  const [validationState, setValidationState] = useState({
    isValid: true,
    message: '',
  });
  const [isDiscardActive, setIsDiscardActive] = useState(false);

  const onSuccess = () => {
    toast('Monitoring Disarm window successfully updated');
    onClose();
  };

  const mutation = useUpdateTenantDisarmWindow(onSuccess);

  const onSubmit = () => {
    if (isDiscardActive) {
      const payload = { ...siteDisarmWindowPayload, startTime: 0, endTime: 1 };
      mutation.mutate(payload);
      return;
    }
    const { isValid, message } = validateDisarmWindow(siteDisarmWindowPayload);
    if (!isValid) {
      setValidationState({ isValid, message });
      return;
    }
    mutation.mutate(siteDisarmWindowPayload);
  };

  const onTimezoneChange = (val: string) => {
    setSiteDisarmWindowPayload((prev) => ({ ...prev, timezone: val }));
  };

  const onDateChange = (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const timestamp = convertDateStringToTimestamp(
      val,
      siteDisarmWindowPayload.timezone
    );
    setSiteDisarmWindowPayload((prev) => ({ ...prev, [key]: timestamp }));
    setValidationState({ isValid: true, message: '' });
  };

  const onDiscard = () => setIsDiscardActive(true);

  const onCancelDiscard = () => setIsDiscardActive(false);

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={mutation.isLoading}
        loading={mutation.isLoading}
        onSideEffect={eventTracker('update_monitoring_disarm_window')}
      >
        Submit
      </Button>
      {!isDiscardActive && isRelevantWindow && (
        <Button
          variant="outline"
          onClick={onDiscard}
          disabled={mutation.isLoading}
        >
          Discard Disarm window
        </Button>
      )}
      <Button onClick={isDiscardActive ? onCancelDiscard : onClose}>
        Cancel
      </Button>
    </>
  );

  const isWindowMoreThan24Hours =
    siteDisarmWindowPayload.endTime - siteDisarmWindowPayload.startTime > 86400;

  return (
    <Modal
      title="Update Monitoring Disarm Window"
      open
      onClose={onClose}
      footer={actions}
    >
      <div className="space-y-4 p-8">
        {mutation.isError && (
          <Alert type="error">{mutation.error.message}</Alert>
        )}
        {!validationState.isValid && (
          <Alert type="warning">{validationState.message}</Alert>
        )}
        {isWindowMoreThan24Hours && (
          <Alert type="info">
            Be aware that time window between start time and end time is more
            than 24 hours
          </Alert>
        )}
        {isDiscardActive && (
          <p className="leading-relaxed">
            This will cancel the temporary disarm period, and <br /> monitoring
            will resume according to the Site Monitoring Schedule.
          </p>
        )}
        {!isDiscardActive && (
          <>
            <Alert type="info">
              Temporarily disarm monitoring for the specified period.
              <br />
              Otherwise, monitoring will follow the Site Monitoring Schedule.
            </Alert>

            <TimezoneSelect
              value={siteDisarmWindowPayload.timezone || 'UTC'}
              onChange={onTimezoneChange}
            />
            <InputField
              label="Start time"
              onChange={onDateChange('startTime')}
              type="datetime-local"
              value={convertTimestampToFormattedDate(
                siteDisarmWindowPayload.startTime,
                siteDisarmWindowPayload.timezone
              )}
              required
            />
            <InputField
              label="End time"
              onChange={onDateChange('endTime')}
              type="datetime-local"
              value={convertTimestampToFormattedDate(
                siteDisarmWindowPayload.endTime,
                siteDisarmWindowPayload.timezone
              )}
              required
            />
          </>
        )}
      </div>
    </Modal>
  );
}

export default UpdateMonitoringDisarmModal;
